<!-- eslint-disable no-param-reassign -->
<template>
  <div class="invoice-payment">
    <b-card
      no-body
      class="border-primary"
    >
      <b-card-header class="bg-primary text-white">
        <h2
          class="mb-0"
          style="color: white;"
        >
          Facture : {{ invoice.id }}
        </h2>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            md="3"
            class="summary-col"
          >
            <strong>Total TTC:</strong>
            <span class="summary-value">{{ calculatedTotals.total_ttc }} DT</span>
          </b-col>
          <b-col
            md="3"
            class="summary-col"
          >
            <strong>Total Payé:</strong>
            <span class="summary-value">{{ calculatedTotals.total_paid }} DT</span>
          </b-col>
          <b-col
            md="3"
            class="summary-col"
          >
            <strong>Total Non payé:</strong>
            <span class="summary-value">{{ calculatedTotals.total_not_paid }} DT</span>
          </b-col>
          <b-col
            md="3"
            class="summary-col"
          >
            <strong>Status: </strong>
            <strong :class="statusClass">{{ paymentStatus }}</strong>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card class="mt-3">
      <b-card-header>
        <h3 class="mb-0">
          Paiements
        </h3>
      </b-card-header>
      <b-card-body>
        <b-alert
          v-if="payments.length === 0"
          show
          variant="info"
        >
          No payments found.
        </b-alert>
        <b-table
          v-else
          striped
          hover
          :items="payments"
          :fields="currentPaymentFields"
          class="payment-table"
        >
          <template #cell(amount)="data">
            <b-form-input
              v-model="data.item.amount"
              type="number"
              step="0.001"
              @input="updateCalculations"
            />
          </template>
          <template #cell(cheque_number)="data">
            <b-form-input
              v-model="data.item.cheque_number"
              type="text"
              placeholder="Enter cheque number"
              :disabled="data.item.payment_method !== 'cheque'"
            />
          </template>
          <template #cell(payment_method)="data">
            <b-form-select
              v-model="data.item.payment_method"
              :options="paymentMethodOptions"
              @input="updatePaymentMethod(data.item)"
            />
          </template>
          <template #cell(due_date)="data">
            <b-form-datepicker
              v-model="data.item.due_date"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              placeholder="Select due date"
              :disabled="data.item.payment_method !== 'cheque'"
            />
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="danger"
              size="sm"
              @click="deletePayment(data.index)"
            >
              Delete
            </b-button>
          </template>
        </b-table>
      </b-card-body>
      <b-card-footer class="d-flex justify-content-between">
        <div>
          <b-button
            v-if="calculatedTotals.total_not_paid > 0"
            variant="primary"
            @click="addNewPayment"
          >
            Add Payment
          </b-button>
        </div>
        <b-button
          variant="info"
          @click="validateChanges"
        >
          Valider paiement
        </b-button>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios'
import {
  BCard, BCardFooter, BCardHeader, BFormSelect, BButton,
  BCol, BRow, BFormInput, BTable, BCardBody, BAlert, BFormDatepicker,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'InvoicePayment',
  components: {
    BFormSelect,
    BButton,
    BCard,
    BCardFooter,
    BCardHeader,
    BCol,
    BRow,
    BFormInput,
    BTable,
    BCardBody,
    BAlert,
    BFormDatepicker,
  },
  data() {
    return {
      invoice: {},
      payments: [],
      basePaymentFields: [
        { key: 'payment_method', label: 'méthode de paiement' },
        { key: 'amount', label: 'Montant' },
        // { key: 'payment_date', label: 'Payment Date' },
        { key: 'actions', label: 'Actions' },
      ],
      chequeFields: [
        { key: 'cheque_number', label: 'Numéro chéque' },
        { key: 'due_date', label: 'Date échéance' },
      ],
      paymentMethodOptions: [
        { value: 'cash', text: 'Espèce' },
        { value: 'cheque', text: 'Chèque' },
      ],
      calculatedTotals: {
        total_ttc: 0,
        total_paid: 0,
        total_not_paid: 0,
        payment_status: 'non payé', // Default status in French
      },
    }
  },
  computed: {
    paymentStatus() {
      if (this.calculatedTotals.total_not_paid <= 0) {
        return 'payé' // Translates to "paid"
      } if (this.calculatedTotals.total_paid > 0) {
        return 'partiellement payé' // Translates to "partially paid"
      }
      return 'non payé' // Translates to "unpaid"
    },
    statusClass() {
      if (this.paymentStatus === 'payé') {
        return 'text-success' // Green for paid
      } if (this.paymentStatus === 'partiellement payé') {
        return 'text-warning' // Yellow for partially paid
      }
      return 'text-danger' // Red for unpaid
    },
    currentPaymentFields() {
      const hasChequePayment = this.payments.some(payment => payment.payment_method === 'cheque')
      return hasChequePayment
        ? [...this.basePaymentFields.slice(0, 2), ...this.chequeFields, ...this.basePaymentFields.slice(2)]
        : this.basePaymentFields
    },
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.fetchInvoiceData()
  },
  methods: {
    async fetchInvoiceData() {
      try {
        const response = await axios.get(`/invoices/${this.id}/payments`)
        this.invoice = response.data
        this.payments = response.data.payments
        this.updateCalculations()
      } catch (error) {
        // console.error('Error fetching invoice data:', error)
      }
    },
    addNewPayment() {
      const newPayment = {
        amount: parseFloat(this.calculatedTotals.total_not_paid),
        payment_method: 'cash',
        // payment_date: new Date().toISOString().split('T')[0],
        cheque_number: '',
        due_date: null,
      }
      this.payments.push(newPayment)
      this.updateCalculations()
    },
    deletePayment(index) {
      this.payments.splice(index, 1)
      this.updateCalculations()
    },
    updateCalculations() {
      const totalPaid = this.payments.reduce((sum, payment) => sum + parseFloat(payment.amount), 0)
      this.calculatedTotals.total_paid = totalPaid.toFixed(3)
      this.calculatedTotals.total_ttc = this.invoice.total_ttc
      this.calculatedTotals.total_not_paid = (parseFloat(this.invoice.total_ttc) - totalPaid).toFixed(3)

      // Automatically update payment status
      if (this.calculatedTotals.total_not_paid <= 0) {
        this.calculatedTotals.payment_status = 'payé' // Update status to "paid"
      } else if (totalPaid > 0) {
        this.calculatedTotals.payment_status = 'partiellement payé' // Update status to "partially paid"
      } else {
        this.calculatedTotals.payment_status = 'non payé' // Update status to "unpaid"
      }
    },
    updatePaymentMethod(payment) {
      if (payment.payment_method === 'cash') {
        // eslint-disable-next-line no-param-reassign
        payment.cheque_number = ''
        // eslint-disable-next-line no-param-reassign
        payment.due_date = null
      }
    },
    async validateChanges() {
      try {
        const paymentsToSend = this.payments.map(payment => {
          if (payment.payment_method === 'cheque') {
            return payment
          }
          const { cheque_number, due_date, ...rest } = payment
          return rest
        })
        await axios.put(`/invoices/${this.id}/validate-payments/`, paymentsToSend)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Paiement ajouté',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        this.$router.push({ name: 'facture-list' })
      } catch (error) {
        // console.error('Error updating payments:', error)
      }
    },
  },
}
</script>

<style scoped>
.invoice-payment {
  padding: 20px;
}

.summary-col {
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 5px;
  text-align: center;
  font-size: medium;
}

.summary-value {
  font-weight: bold;
  color: #27603f;
}

.payment-table {
  margin-top: 10px;
}

.payment-table th {
  background-color: #e9ecef;
}

.b-card-header {
  border-bottom: 2px solid #007bff;
}

.b-button {
  transition: background-color 0.3s;
}

.b-button:hover {
  background-color: #0056b3;
}

.text-success {
  color: green; /* For paid status */
}

.text-warning {
  color: orange; /* For partially paid status */
}

.text-danger {
  color: red; /* For unpaid status */
}
</style>
